const layer = {
    'is-background-layer': true,
    'id': 'luchtfoto-pdok',
    'type': 'raster',
    'source': 'luchtfoto-pdok',
    'paint': {
        // "raster-opacity": 0
    },
}
  
export default layer;
